<template>
  <v-carousel
    style="border-radius: 20px"
    :height="carrusel.height"
    hide-delimiter-background
    :show-arrows="carrusel.hideButtons"
    :hide-delimiters="!carrusel.hideThumbnails"
    color="#fff"
    continuous
    cycle
    class="carouselPage"
  >
    <v-carousel-item
      v-for="(item, index) in carrusel.images.filter((x) => x.active == true)"
      :key="index"
      :src="item.url"
      cover
    >
      <template v-slot:prev="{ props }">
        <v-btn
          size="small"
          color="#fff"
          icon="mdi-arrow-left"
          variant="outlined"
          @click="props.onClick"
        ></v-btn>
      </template>
      <template v-slot:next="{ props }">
        <v-btn
          size="small"
          color="#fff"
          variant="outlined"
          icon="mdi-arrow-right"
          @click="props.onClick"
        ></v-btn>
      </template>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Carrusel",
  props: ["carrusel"],
  data() {
    return {};
  },
  mounted() {},
};
</script>
