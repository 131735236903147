import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticStyle:{"border-radius":"10px"},attrs:{"elevation":"0"}},[(_vm.snackbar)?_c(VSnackbar,{staticStyle:{"z-index":"1000"},attrs:{"color":_vm.color,"location":"top","top":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VBtn,{attrs:{"color":"#fff","small":"","icon":"mdi-close"},on:{"click":function($event){_vm.snackbar = false}}})]},proxy:true}],null,false,1476854800),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),_c(VCardTitle,[_c('h3',[_vm._v(_vm._s(_vm.section.title))])]),_c(VDivider),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,_vm._l((_vm.section.customForm.customFields),function(input,i){return _c(VCol,{key:i,attrs:{"cols":input.size}},[_c('label',{staticClass:"text-caption"},[_vm._v(_vm._s(input.label))]),(input.type === 'phone' && _vm.showPhone)?_c('inputPhone',{on:{"retunValue":(e, code) => {
    input.value = `${code.callingCodes[0]}${e}`;
  }}}):_vm._e(),(input.type == 'select')?_c(VSelect,{staticStyle:{"border-radius":"10px !important"},attrs:{"variant":"outlined","items":input.items,"item-title":"text","dense":"","rules":input.required ? [(v) => !!v || 'Campo requerido'] : [],"item-value":"value"},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}}):_vm._e(),(input.type == 'text' || input.type == 'tel')?_c(VTextField,{staticStyle:{"border-radius":"10px !important"},attrs:{"outlined":"","required":"","type":input.type,"dense":"","rules":input.required ? [(v) => !!v || 'Campo requerido'] : []},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}}):_vm._e(),(input.type == 'switch')?_c(VSwitch,{attrs:{"color":"primary","dense":"","label":input.label},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}}):_vm._e(),(input.type == 'textarea')?_c(VTextarea,{staticStyle:{"border-radius":"10px"},attrs:{"rules":input.required ? [(v) => !!v || 'Campo requerido'] : [],"hide-details":"","rounded":"","filled":""},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}}):_vm._e()],1)}),1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{staticClass:"px-4",staticStyle:{"color":"#fff !important","border-radius":"10px","text-transform":"capitalize"},attrs:{"block":"","variant":"flat","dark":"","depressed":"","color":"primary","loading":_vm.loading},on:{"click":_vm.validateForm}},[_vm._v(" Enviar ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }