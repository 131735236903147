<template>
  <v-card class="d-flex align-center justify-center document" elevation="0" height="100%" width="100%" style="border-radius: 10px"
    flat>
    <v-card-text class="font-weight-bold d-flex align-center pa-0" style="padding: 0">
      <img src="@/assets/pdf-icon.svg" draggable="false" class="mr-3" width="30px" />

      <span style="color: #000; font-weight: 400; font-size: 14px">{{ document.name }}
      </span>
    </v-card-text>

    <v-card-actions>
      <span class="text-caption" style="color: #757575;  text-wrap: nowrap;">Tamaño del archivo:
        <strong>{{ document.size | bytes }}</strong>


      </span>



      <div class="d-flex" style="gap: 10px">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ml-2" :loading="loading"  fab dark v-bind="attrs" v-on="on" x-small depressed
              @click="viewDoc(document)"><v-icon>mdi-file</v-icon></v-btn>
          </template>
          <span>Visualizar</span>
        </v-tooltip>

        <v-tooltip bottom>

          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark x-small :loading="loadingD" depressed v-bind="attrs" v-on="on"
              @click="downloadDocument(document)"><v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar</span>
        </v-tooltip>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

import bytesToMB from "@/utilities/convertBytesToMb";

export default {
  name: "Document",
  props: ["document"],
  data(){
    return{
      loading:false,
      loadingD:false,
    }
  },
  filters: {
    bytes(bits) {
      return `${bytesToMB(bits).toFixed(2)}mb`;
    },

  },
  methods: {
    async viewDoc(item) {
      this.loading = true;
      try {
        const { data, headers } = await axios.get(
          `https://api-documentos.enee.hn/api/files/download/${item.key}`,
          { responseType: "blob" }
        );
        const blob = new Blob([data], {
          type: headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);

        const newTab = window.open(url, "_blank");

        newTab?.addEventListener("unload", () => {
          URL.revokeObjectURL(url);
        });
        setTimeout(() => {
           this.loading = false;
        }, 1000);
      } catch (error) {
         this.loading = false;
        throw new Error(error.message);
      }
    },

    async downloadDocument(item) {
       this.loadingD = true;
      try {
        const link = document.createElement("a");
        link.href = `https://api-documentos.enee.hn/api/files/download/${item.key}`;
        link.setAttribute("download", item.file_name);
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
           this.loadingD = false;
        }, 1000);
      } catch (error) {
         this.loadingD = false;
        throw new Error(error.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive";

.document {
  @include responsive(mobile) {
    flex-direction: column;
  }
}

.icon {
  height: 50px;
  width: 50px;

  @include responsive(mobile) {
    height: 35px;
    width: 35px;
  }
}
</style>
