<template>
  <div class="rounded">
    <v-menu offset-y class="rounded">
      <template v-slot:activator="{ on, attrs }">
        <div dark v-bind="attrs" v-on="on">
          {{ user.name }}
          <v-icon color="primary" large>mdi-account-circle</v-icon>
        </div>
      </template>
      <v-list style="width: 250px; gap: 10px" class="d-flex flex-column">
        <v-list-item class="d-flex justify-center align-center mt-5">
          <v-icon style="font-size: 56px" color="primary"
            >mdi-account-circle</v-icon
          >
        </v-list-item>
        <div>
          <v-list-item class="pa-0 height">
            <v-list-item-title class="text-center">{{
              user.name
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item class="pa-0 height">
            <v-list-item-title class="text-center">{{
              user.email
            }}</v-list-item-title>
          </v-list-item>
        </div>
        <v-list-item>
          <v-btn
            color="white--text"
            style="
              border-radius: 10px;
              background-color: #2f2f2f;
              text-transform: capitalize;
            "
            block
            @click="$emit('logout')"
            >Cerrar Sesión</v-btn
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "userInfoDropdown",
  props: ["user"],
};
</script>

<style scoped>
.blue-color {
  color: #59c6d5;
}

.height {
  min-height: 25px;
}

.v-menu__content {
  border-radius: 10px !important;
}
</style>
