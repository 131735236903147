


import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import {env} from "@/environment/env";


// let env = process.env.NODE_ENV;
//
// var prodConfig = {
//   apiKey: "AIzaSyC6QWs_OOE9bX8L4BmJlsF8qZf0cinPTng",
//   authDomain: "enee-hn.firebaseapp.com",
//   databaseURL: "https://enee-hn-default-rtdb.firebaseio.com",
//   projectId: "enee-hn",
//   storageBucket: "enee-hn.appspot.com",
//   messagingSenderId: "983231514624",
//   appId: "1:983231514624:web:fc2c9037be00ee7b10f0e5",
//   measurementId: "G-F1B000Z68M"
// };
//
// const config_dev = {
//   apiKey: "AIzaSyDOYvpGfxdE85t3HvVU-GhWyZg-tX782vQ",
//   authDomain: "enee-dev.firebaseapp.com",
//   databaseURL: "https://enee-dev-default-rtdb.firebaseio.com",
//   projectId: "enee-dev",
//   storageBucket: "enee-dev.appspot.com",
//   messagingSenderId: "408411139805",
//   appId: "1:408411139805:web:4ac4659de5b9b360ce3534",
//   measurementId: "G-GB3Z34LQGC"
// };


// firebase.initializeApp(env == "production" ? prodConfig : config_dev);

const config = {
    apiKey: env.API_KEY,
    authDomain: env.AUTH_DOMAIN,
    databaseURL: env.DATABASE_URL,
    projectId: env.PROJECT_ID,
    storageBucket: env.STORAGE_BUCKET,
    messagingSenderId: env.MESSAGING_SENDER_ID,
    appId: env.APP_ID,
    measurementId: env.MEASUREMENT_ID
}

firebase.initializeApp(config)



firebase.auth().languageCode = "es";

export const db = firebase.firestore();
export const fb = firebase;
