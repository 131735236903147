<template>
  <div>
    <v-card-title class="text-h6 d-block text-center">
      {{ section.title }}
    </v-card-title>

    <div class="documents d-grid">
      <document
        :document="document"
        v-for="(document, index) in documentsList"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase";

import document from "@/components/document";

export default {
  name: "documents",
  props: ["section", "authenticated"],
  components: { document },
  data() {
    return {
      documents: [],
    };
  },
  computed: {
    documentsList() {
      return this.documents.filter((document) =>
        this.section.filesIds.includes(document[".key"])
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$binding(
        "documents",
        db.collection("files").where("active", "==", true)
      );
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive";

.documents {
  background-color: #62c3db5c;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 50px 70px;

  @include responsive(mobile) {
    padding: 30px;
  }

  @include responsive(small) {
    padding: 35px;
  }
}

.documents {
  div {
    background-color: transparent;
  }
}
</style>
