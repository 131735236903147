<template>
  <v-card style="border-radius: 20px">
    <v-card-title class="d-flex"
      >Ingresa tus credenciales
      <v-btn absolute right icon @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text class="pb-6">
      <v-form
        style="width: 100%"
        ref="form"
        v-model="valid"
        lazy-validation
        @keyup.enter.native="onNext"
      >
        <v-text-field
          label="Correo Electrónico"
          v-model="email"
          :rules="[rules.required, rules.email]"
          ref="mailAddress"
          type="email"
          hint="correo@servidor.tldn"
          autocomplete="email"
          filled
          style="border-radius: 10px"
          :disabled="loading"
          rounded
          dense
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="Contraseña"
          hint="la contraseña debe tener más de 8 carácteres"
          autocomplete="current-password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          filled
          :disabled="loading"
          rounded
          dense
          style="border-radius: 10px"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-btn
          style="float: right"
          right
          @click="passwordReset"
          x-small
          text
          color="primary"
        >
          ¿Olvidaste tu contraseña?
        </v-btn>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="d-flex flex-column">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        block
        :loading="loading"
        large
        :elevation="0"
        :minHeight="50"
        @click="onNext"
        ><b>Ingresar</b></v-btn
      >
      <!-- <v-btn
     
        color="gray"
        dense
        block
        large
        text
        :elevation="0"
        :loading="loading"
        :disabled="loading"
        >Olvidaste tu contraseña?</v-btn
      > -->
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

import { db, fb } from "@/firebase";
import alertMixins from "@/mixins/alertMixins";

export default {
  name: "LoginForm",
  mixins: [alertMixins],
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      showPassword: false,
      valid: true,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Inválido";
        },
      },
    };
  },
  methods: {
    async logAccess(response, result) {
      let ip = await axios({
        url: "https://get.geojs.io/v1/ip/geo.json",
      });

      let data = {
        result,
        ip: ip.data ? ip.data : {},
        date: new Date(),
        domain: document.domain,
      };

      if (result == "success") {
        data.uid = response.user.uid;
        data.email = response.user.email;
      } else {
        data.email = this.email;
        data.error = {
          code: response.code || "no disponible",
          message: response.message,
        };
      }

      await db.collection("accessLogBidding").add(data);
    },

    passwordReset() {
      let emailAddress = this.email;
      fb.auth().languageCode = "es";

      if (this.$refs.mailAddress.validate()) {
        fb.auth()
          .sendPasswordResetEmail(emailAddress)
          .then(() => {
            this.alertMessage(
              "info",
              "Correo enviado, revisa tu bandeja de entrada"
            );
          })
          .catch(() => {
            this.alertMessage(
              "error",
              "Error al enviar correo, verifica tu conexión a internet"
            );
          });
      } else {
        this.alertMessage(
          "error",
          "Ingresa tu dirección de correo electrónico para enviarte un enlace de reinicio de contraseña"
        );
      }
    },

    onNext() {
      this.loading = true;

      if (this.$refs.form.validate()) {
        // fb.auth()
        //   .setPersistence(fb.auth.Auth.Persistence.SESSION)
        //   .then(() => {

        fb.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then((response) => {
            this.loading = false;
            this.logAccess(response, "success");
            this.$emit("close");
          })
          .catch((error) => {
            this.loading = false;
            // Handle Errors here.

            this.logAccess(error, "failed");
            switch (error.code) {
              case "auth/multi-factor-auth-required":
                this.authObject = error.resolver;
                this.mfaLogin();
                break;

              case "auth/invalid-email":
                this.alertMessage(
                  "error",
                  "Por favor ingresa la dirección de correo correctamente"
                );
                break;

              case "auth/user-not-found":
                this.alertMessage(
                  "error",
                  "No existen usuarios con este correo. Verifica tu dirección o ponte en contacto con el administrador."
                );

                break;

              case "auth/wrong-password":
                this.alertMessage("error", "Contraseña incorrecta");

                break;
              case "auth/invalid-login-credentials":
                this.alertMessage("error", "Credenciales incorrectas");

                break;

              case "auth/argument-error":
                this.alertMessage(
                  "error",
                  "El correo y la contraseña deben ser campos validos"
                );
                break;

              default:
                this.alertMessage("error", error.message);

                break;
            }
            // ...
          });
        // });
      } else {
        this.alertMessage("error", "Llena todos los campos para continuar");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
