import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('preloader',{staticStyle:{"z-index":"10000"}}),_c(VAppBar,{attrs:{"app":"","elevation":"0","color":"#fff"}},[_c(VContainer,{staticClass:"d-flex align-center justify-space-between navbar"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo-01.svg"),"alt":"ENEE Logo"}}),(_vm.user)?_c('userInfoDropdown',{attrs:{"user":_vm.user},on:{"logout":_vm.logOut}}):_c(VBtn,{staticStyle:{"border-radius":"10px","text-transform":"capitalize"},attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.openModal}},[_vm._v(" Iniciar sesión ")])],1)],1),_c(VMain,{staticStyle:{"background-color":"#f8f8f8"}},[_c('router-view')],1),_c('Snackbar',{staticStyle:{"z-index":"1000","width":"100%","position":"fixed"}}),(_vm.showDialog)?_c(VDialog,{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('loginForm',{on:{"close":_vm.closeModal}})],1):_vm._e(),(_vm.resetPasswordDialog)?_c(VDialog,{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.resetPasswordDialog),callback:function ($$v) {_vm.resetPasswordDialog=$$v},expression:"resetPasswordDialog"}},[_c('resetPassword',{on:{"close":_vm.closeModal}})],1):_vm._e(),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }