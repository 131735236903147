import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded"},[_c(VMenu,{staticClass:"rounded",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({attrs:{"dark":""}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user.name)+" "),_c(VIcon,{attrs:{"color":"primary","large":""}},[_vm._v("mdi-account-circle")])],1)]}}])},[_c(VList,{staticClass:"d-flex flex-column",staticStyle:{"width":"250px","gap":"10px"}},[_c(VListItem,{staticClass:"d-flex justify-center align-center mt-5"},[_c(VIcon,{staticStyle:{"font-size":"56px"},attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")])],1),_c('div',[_c(VListItem,{staticClass:"pa-0 height"},[_c(VListItemTitle,{staticClass:"text-center"},[_vm._v(_vm._s(_vm.user.name))])],1),_c(VListItem,{staticClass:"pa-0 height"},[_c(VListItemTitle,{staticClass:"text-center"},[_vm._v(_vm._s(_vm.user.email))])],1)],1),_c(VListItem,[_c(VBtn,{staticStyle:{"border-radius":"10px","background-color":"#2f2f2f","text-transform":"capitalize"},attrs:{"color":"white--text","block":""},on:{"click":function($event){return _vm.$emit('logout')}}},[_vm._v("Cerrar Sesión")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }