<template>
  <v-card elevation="0" style="border-radius: 10px">
    <v-snackbar v-model="snackbar" v-if="snackbar" :color="color" location="top" top style="z-index: 1000">
      {{ text }}
      <template v-slot:actions>
        <v-btn color="#fff" small icon="mdi-close" @click="snackbar = false"></v-btn>
      </template>
    </v-snackbar>
    <v-card-title>
      <h3>{{ section.title }}</h3>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col v-for="(input, i) in section.customForm.customFields" :key="i" :cols="input.size">
            <label class="text-caption">{{
      input.label
    }}</label>
            <inputPhone v-if="input.type === 'phone' && showPhone" @retunValue="(e, code) => {
      input.value = `${code.callingCodes[0]}${e}`;
    }" />
            <v-select v-if="input.type == 'select'" style="border-radius: 10px !important" variant="outlined"
              :items="input.items" v-model="input.value" item-title="text" dense
              :rules="input.required ? [(v) => !!v || 'Campo requerido'] : []" item-value="value"></v-select>
            <v-text-field v-if="input.type == 'text' || input.type == 'tel'" style="border-radius: 10px !important"
              outlined v-model="input.value" required :type="input.type" dense
              :rules="input.required ? [(v) => !!v || 'Campo requerido'] : []"></v-text-field>
            <v-switch v-if="input.type == 'switch'" color="primary" dense :label="input.label"
              v-model="input.value"></v-switch>

            <v-textarea v-if="input.type == 'textarea'" :rules="input.required ? [(v) => !!v || 'Campo requerido'] : []"
              style="border-radius: 10px" hide-details rounded filled v-model="input.value"></v-textarea>
          </v-col>
        </v-row>
      </v-form>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn block variant="flat" dark depressed color="primary" :loading="loading" class="px-4" @click="validateForm"
        style="
          color: #fff !important;
          border-radius: 10px;
          text-transform: capitalize;
        ">
        Enviar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import alertMixins from "@/mixins/alertMixins";
import inputPhone from "./inputPhone.vue";

export default {
  name: "customForm",
  props: ["section", "keyPage"],
  components:{
    inputPhone
  },
  mixins: [alertMixins],
  data() {
    return {
      snackbar: false,
      text: "Llene los campos solicitados 😬",
      color: "error",
      valid: true,
      phone: null,
      code: null,
      showPhone:true,
      loading: false,
    };
  },
  methods: {
    async validateForm() {
      const valid = await this.$refs.form.validate();
      this.loading = true;
      if (valid) {
        this.onNext();
      } else {
        this.text = "Llene los campos solicitados 😬";
        this.color = "error";
        this.snackbar = true;
        this.loading = false;
      }
    },
    valueInput(e, code) {
      this.phone = e;
      this.code = code;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async onNext() {
      const dataform = {};
      this.section.customForm.customFields.forEach((element) => {
        dataform[`${element.label.toLowerCase().replace(" ", "")}`] =
          element.value;
      });
      dataform.createdAt = new Date();
      dataform.section = this.section[`.key`];
      dataform.page = this.keyPage;
      dataform.descriptionForm = this.section.description;
      dataform.titleForm = this.section.title;

      await db.collection("biddingFormBox")
        .add(dataform).then(response => {
          this.alertMessage(
            "success",
            "Información enviada correctamente"
          )
          this.loading = false;
      this.section.customForm.customFields.forEach((element) => {
        element.value = null;
      });
      this.showPhone= false;
      setTimeout(() => {
        this.showPhone= true;
        
      }, 1000);

        }).catch(error => {
          this.alertMessage(
            "error",
            "Ocurrio un error al enviar la información"
          );
          this.loading = false;

        })

    },
  },
};
</script>

<style>
.v-input__slot {
  background-color: #f2f2f2 !important;
  border: none !important;
  outline: none;
}

fieldset {
  border: none !important;
}

label {
  color: rgb(42, 42, 42);
}
</style>
