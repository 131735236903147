<template>
  <div class="text-center snackbar-z">
    <v-snackbar
      v-model="snackbar"
      :timeout="options.timeout"
      centered
      transition="scroll-y-transition"
      top
      :color="options.type"
      rounded="lg"
      absolute
      elevation="24"
      class="text-center"
    >
      <v-icon color v-if="options.icon"> {{ options.iconType }} </v-icon>
      {{ options.text }}

      <template v-if="options.btn_closed == true" v-slot:action="{ attrs }">
        <v-btn color="blue" icon text v-bind="attrs" @click="snackbar = false">
          <v-icon color="#fff"> mdi-close-circle-outline </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import store from "@/store/index.js";

export default {
  name: "Snackbar",
  components: {},
  props: {},
  data() {
    return {
      snackbar: false,
      options: {
        text: "",
        timeout: 2000,
        btn_closed: false,
        icon: true,
        iconType: "",
        type: "success",
      },
    };
  },
  mounted() {},
  computed: mapGetters(["Alert_"]),
  watch: {
    Alert_(e) {
      this.options = e;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/_responsive.scss";
.snackbar-z {
  z-index: 1000;
}
// success
// info
// warning
// error
</style>
