import {createVirtualPATH, withBaseName} from "@/environment/virtualPath";

const jsEnv = window._env_;

const VIRTUAL_PATH = createVirtualPATH(jsEnv);

export const env = {
    APP_URL: jsEnv.APP_URL,
    VERSION: jsEnv.VERSION,
    SYSTEM_NAME: jsEnv.SYSTEM_NAME,
    API_KEY: jsEnv.API_KEY,
    AUTH_DOMAIN: jsEnv.AUTH_DOMAIN,
    DATABASE_URL: jsEnv.DATABASE_URL,
    PROJECT_ID: jsEnv.PROJECT_ID,
    STORAGE_BUCKET: jsEnv.STORAGE_BUCKET,
    MESSAGING_SENDER_ID: jsEnv.MESSAGING_SENDER_ID,
    APP_ID: jsEnv.APP_ID,
    MEASUREMENT_ID: jsEnv.MEASUREMENT_ID,
    BASENAME: withBaseName(VIRTUAL_PATH),
    BASE_URL: VIRTUAL_PATH,
}