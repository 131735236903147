import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
Vue.use(Vuex);
const vuexPersist = new VuexPersist({
  key: "licitaciones-enee",
  storage: window.localStorage,
  modules: ["user"],
});

export default new Vuex.Store({
  state: {
    user: null,
    role: "",

    SnackbarAlert: {
      text: "",
      timeout: 2000,
      btn_closed: false,
      icon: false,
      type: "",
    },
  },
  mutations: {
    AlertSnackbar(state, config) {
      state.SnackbarAlert = config;
      return state.SnackbarAlert;
    },

    setUser(state, target) {
      state.user = target;
      state.role = target?.role || "";
    },
  },
  actions: {
    Alert_: ({ commit, state }, config) => {
      commit("AlertSnackbar", config);
    },

    setUser: ({ commit, state }, user) => {
      commit("setUser", user);
    },
  },
  getters: {
    Alert_: (state) => {
      return state.SnackbarAlert;
    },
  },
  modules: {},
  plugins: [vuexPersist.plugin],
});
