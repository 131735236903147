<template>
  <footer height="auto" class="grey darken-4 pt-12 ">
    <v-container class=" text-center flex-column pb-6" >
     <div class="wrap-logos">
      <img src="@/assets/logo-text-white.svg" class="xs-d-none" height="45px" alt="ENEE Logo" />
      <img src="@/assets/logoGob.svg" height="45px" alt="ENEE Logo" />
     </div>


    </v-container>
    <div style="text-align: center;" class="text-caption caption">Licitación Pública Internacional LPI-100-010-2021 de 1500 MW</div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.wrap-logos{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-column{
display: flex;
flex-direction: column;


}
footer{
  color: #b3b3b3;
}
.caption{
  width: 100%;
  padding: 3px;
  background-color: rgb(24, 24, 24);
}

</style>
