<template>
  <v-card style="border-radius: 20px">
    <v-card-title class="d-flex"
      >Ingresa tu nueva contraseña
      <v-btn absolute right icon @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-form
        style="width: 100%"
        ref="form"
        v-model="valid"
        lazy-validation
        @keyup.enter.native="onNext"
      >
        <v-text-field
          v-model="password"
          label="Contraseña"
          hint="La contraseña debe tener más de 8 carácteres"
          autocomplete="current-password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          filled
          full-width
          :disabled="loading"
          rounded
          style="border-radius: 10px"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-text-field
          v-model="password2"
          :color="password == password2 && password != '' ? 'success' : ''"
          label="Confirmar contraseña"
          hint="La contraseña debe tener más de 8 carácteres"
          autocomplete="current-password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="password == password2 ? [] : rules2"
          :type="showPassword ? 'text' : 'password'"
          filled
          :disabled="loading"
          rounded
          dense
          style="border-radius: 10px"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="d-flex flex-column">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        block
        :disabled="password == password2 && password != '' ? false : true"
        :loading="loading"
        large
        :elevation="0"
        :minHeight="50"
        @click="passwordReset"
        ><b>Continuar</b></v-btn
      >
      <!-- <v-btn
        color="gray"
        dense
        block
        large
        text
        :elevation="0"
        :loading="loading"
        :disabled="loading"
        >Olvidaste tu contraseña?</v-btn
      > -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { fb } from "@/firebase";
import alertMixins from "@/mixins/alertMixins";

export default {
  name: "ResetPasswordForm",
  mixins: [alertMixins],
  data() {
    return {
      loading: false,
      email: "",

      password: "",
      password2: "",
      showPassword: false,
      valid: true,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
        email: (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "Correo Inválido";
        },
      },
      rules2: [
        (value) => !!value || "Obligatorio",
        (value) => value === this.password || "Las contraseñas deben coincidir",
        (value) => value !== this.password || "",
        (value) =>
          (value.length >= 8 && value.length <= 8) || "Mínimo 8 caracteres",
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["setUser"]),
    logOut() {
      fb.auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("licitaciones-enee");
          this.setUser(null);
          setTimeout(() => {
            this.alertMessage(
              "info",
              "Vuelve a iniciar sesión con tu contraseña nueva"
            );
          }, 2000);
        });
    },
    passwordReset() {
      this.loading = true;
      this.loading = true;
      fb.auth().languageCode = "es";

      if (this.$refs.form.validate()) {
        const usersResetPasswordOfferer = fb
          .functions()
          .httpsCallable("usersResetPasswordOfferer");

        const data = {
          userId: this.user[".key"],
          password: this.password2,
        };

        usersResetPasswordOfferer(data)
          .then(() => {
            this.alertMessage(
              "success",
              "Contraseña Actualizada correctamente"
            );

            this.logOut();
            this.$emit("close");
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.alertMessage("error", "Ingresa los campos solicitados");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.border-success {
  background-color: #4be55b;
}
</style>
