import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"d-flex align-center justify-center document",staticStyle:{"border-radius":"10px"},attrs:{"elevation":"0","height":"100%","width":"100%","flat":""}},[_c(VCardText,{staticClass:"font-weight-bold d-flex align-center pa-0",staticStyle:{"padding":"0"}},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/pdf-icon.svg"),"draggable":"false","width":"30px"}}),_c('span',{staticStyle:{"color":"#000","font-weight":"400","font-size":"14px"}},[_vm._v(_vm._s(_vm.document.name)+" ")])]),_c(VCardActions,[_c('span',{staticClass:"text-caption",staticStyle:{"color":"#757575","text-wrap":"nowrap"}},[_vm._v("Tamaño del archivo: "),_c('strong',[_vm._v(_vm._s(_vm._f("bytes")(_vm.document.size)))])]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.loading,"fab":"","dark":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.viewDoc(_vm.document)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-file")])],1)]}}])},[_c('span',[_vm._v("Visualizar")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","x-small":"","loading":_vm.loadingD,"depressed":""},on:{"click":function($event){return _vm.downloadDocument(_vm.document)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Descargar")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }