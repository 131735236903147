<template>
  <v-img
    style="border-radius: 10px"
    aspect-ratio="16/9"
    cover
    :src="imageData.image.url"
  >
    <v-card-text class="text-imagen">
      {{ imageData.description }}
    </v-card-text>
  </v-img>
</template>

<script>
export default {
  name: "imageItem",
  props: ["imageData"],
};
</script>

<style scoped></style>
