<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          v-for="(item, index) in sortPage"
          :key="index"
          :md="item.size"
          :lg="item.size"
          :sm="item.size"
          cols="12"
          :style="item.customStyle"
        >
          <carrusel
            v-if="item.type === types.Carrusel"
            :carrusel="item"
            :style="item.customStyle"
          />

          <imageItem
            :imageData="item"
            :style="item.customStyle"
            v-if="item.type === types.Imagen"
          />

          <documentsList
            v-if="item.type === types.Documentos"
            :section="item"
            :style="item.customStyle"
            :authenticated="false"
          />

          <div
            class="d-flex justify-center align-center mt-8"
            v-if="item.type === 'login' && !user"
          >
            <v-btn
              color="primary"
              depressed
              style="border-radius: 10px; text-transform: capitalize"
              @click="openModal"
              >Iniciar Sesión</v-btn
            >
          </div>
          <customForm
            v-if="item.type === types.Formulario"
            :section="item"
            keyPage="FKB1z7OHUAQjlBSmquca"
          />
          <textContent
            v-if="item.type === types.Texto"
            :text="item"
            :style="item.customStyle"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <loginForm @close="closeModal" />
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";

import carrusel from "@/components/carrusel";
import customForm from "@/components/customForm";
import documentsList from "@/components/documentsList";
import imageItem from "@/components/imageItem";
import loginForm from "@/components/loginForm";
import textContent from "@/components/textContent";
import { mapActions, mapState } from "vuex";
import SectionTypes from "@/utilities/sectionTypes";

export default {
  name: "Home",
  components: {
    carrusel,
    customForm,
    documentsList,
    imageItem,
    loginForm,
    textContent,
  },
  data() {
    return {
      bidding: [],
      showDialog: false,
      types: SectionTypes,
    };
  },
  computed: {
    ...mapState(["user"]),

    sortPage() {
      var bidding = Object.assign(
        [],
        this.bidding.map((e) => {
          if (e.showUserLogin && !this.user) {
            e.type = "none";
            return e;
          } else {
            return e;
          }
        })
      );
      bidding.push({
        position: 2,
        type: "login",
        active: true,
        size: 12,
      });
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return bidding.sort(compare);
    },
  },
  mounted() {
    this.getSections();
  },
  watch: {
    user() {
      // this.bidding = [];
      this.getSections();
    },
  },
  methods: {
    openModal() {
      this.showDialog = true;
    },
    async getSections() {
      await this.$binding(
        "bidding",
        db
          .collection("biddingPages")
          .doc("FKB1z7OHUAQjlBSmquca")
          .collection("sections")
          .where("deleted", "==", false)
          .where("active", "==", true)
      );
    },
    closeModal() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss">
.text-imagen {
  position: absolute;
  bottom: 0px;
  color: #fff;
  width: 100%;
  height: auto;
  left: 0;
  background-color: rgba(0, 0, 0, 0.344);
}
</style>
