import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,[_c(VRow,_vm._l((_vm.sortPage),function(item,index){return _c(VCol,{key:index,style:(item.customStyle),attrs:{"md":item.size,"lg":item.size,"sm":item.size,"cols":"12"}},[(item.type === _vm.types.Carrusel)?_c('carrusel',{style:(item.customStyle),attrs:{"carrusel":item}}):_vm._e(),(item.type === _vm.types.Imagen)?_c('imageItem',{style:(item.customStyle),attrs:{"imageData":item}}):_vm._e(),(item.type === _vm.types.Documentos)?_c('documentsList',{style:(item.customStyle),attrs:{"section":item,"authenticated":false}}):_vm._e(),(item.type === 'login' && !_vm.user)?_c('div',{staticClass:"d-flex justify-center align-center mt-8"},[_c(VBtn,{staticStyle:{"border-radius":"10px","text-transform":"capitalize"},attrs:{"color":"primary","depressed":""},on:{"click":_vm.openModal}},[_vm._v("Iniciar Sesión")])],1):_vm._e(),(item.type === _vm.types.Formulario)?_c('customForm',{attrs:{"section":item,"keyPage":"FKB1z7OHUAQjlBSmquca"}}):_vm._e(),(item.type === _vm.types.Texto)?_c('textContent',{style:(item.customStyle),attrs:{"text":item}}):_vm._e()],1)}),1)],1),(_vm.showDialog)?_c(VDialog,{attrs:{"scrollable":"","persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('loginForm',{on:{"close":_vm.closeModal}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }