<template>
  <div v-html="text.content.description" v-if="text"></div>
</template>

<script>
export default {
  name: "TextContent",
  props: ["text"],
};
</script>

<style lang="scss" scoped></style>
