<template>
  <v-app>
    <preloader style="z-index: 10000" />
    <v-app-bar app elevation="0" color="#fff">
      <v-container class="d-flex align-center justify-space-between navbar">
        <img src="@/assets/logo-01.svg" alt="ENEE Logo" class="logo" />

        <userInfoDropdown v-if="user" :user="user" @logout="logOut" />
        <v-btn
          elevation="0"
          v-else
          style="border-radius: 10px; text-transform: capitalize"
          color="primary"
          @click="openModal"
        >
          Iniciar sesión
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main style="background-color: #f8f8f8">
      <router-view />
    </v-main>

    <Snackbar style="z-index: 1000; width: 100%; position: fixed" />

    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <loginForm @close="closeModal" />
    </v-dialog>
    <v-dialog
      v-model="resetPasswordDialog"
      v-if="resetPasswordDialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <resetPassword @close="closeModal" />
    </v-dialog>

    <Footer />
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import preloader from "@/components/preloader.vue";
import { db, fb } from "@/firebase";

import alertMixins from "./mixins/alertMixins";
import Footer from "@/components/footer";
import loginForm from "@/components/loginForm";
import resetPassword from "@/components/resetPassword";
import Snackbar from "./components/snackbar";
import userInfoDropdown from "./components/userInfoDropdown";

export default {
  name: "App",
  mixins: [alertMixins],
  components: {
    Snackbar,
    userInfoDropdown,
    Footer,
    loginForm,
    preloader,
    resetPassword,
  },
  data() {
    return {
      userData: null,
      showDialog: false,
      resetPasswordDialog: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    this.$nextTick(() => {
      fb.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          this.$binding(
            "userData",
            db.collection("users").doc(authUser.uid)
          ).then((user) => {
            if (user.type !== "offerer" || user.active == false) {
              // console.debug("no entra");
              this.alertMessage(
                "error",
                "Este usuario no tiene permitido ingresar a la plataforma"
              );
              this.logOut();
            } else {
              if (!user.resetPass) {
                this.resetPasswordDialog = true;
              }
              this.userData = user;
              this.setUser(user);
              // console.debug("Inicio sesion");
            }
          });
        }
      });
    });
  },
  methods: {
    ...mapActions(["setUser"]),
    logOut() {
      if (this.userData) {
        fb.auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("licitaciones-enee");
            this.userData = null;
            this.setUser(null);
          });
      }
    },
    openModal() {
      this.showDialog = true;
    },
    closeModal() {
      this.showDialog = false;
      this.resetPasswordDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive";

h1 {
  @include responsive(mobile) {
    font-size: 18px;
  }
}

.logo {
  height: 40px;

  @include responsive(mobile) {
    height: 30px;
  }
}
</style>
